import { Api } from "./api";

export default {
  getSaldo: (payload) =>
    Api.get("/v2/balance", {
      headers: { token: payload.token },
    }),
  getSaldoTransaction: (payload) =>
    Api.get("/v2/balance/history", {
      headers: { token: payload.token },
      params: payload.params,
    }),
  getSaldoEstimated: (payload) =>
    Api.get("/v2/balance/estimated", {
      headers: { token: payload.token },
    }),
  requestWithdrawal: (payload) =>
    Api.post(`/v2/balance/withdraw/request-otp`, payload.data, {
      headers: { token: payload.token },
    }),
  confirmOtpRequestWithdrawal: (payload) =>
    Api.post(
      `/v2/balance/withdraw/confirm-otp`,
      {
        otp_code: payload.otp_code,
      },
      {
        headers: { token: payload.token },
      }
    ),
  resendOTP: (payload) =>
    Api.get(`/v1/withdraw/verify-otp`, {
      headers: { token: payload.token },
    }),
};
