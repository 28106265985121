import { put, call, takeLatest, select } from "redux-saga/effects";
import Api from "../../services";
import types from "./types";
import * as actions from "./reducer";
import * as actionsDeposit from "../../components/account/pages/deposit/reducer";
import { notification } from "../../helpers/notification";

function* watchRequestWithdrawal(value) {
  yield put(actions.setIsLoadingRequestOtpWithdraw(true));
  const { payload } = value;
  try {
    const response = yield call(Api.deposit.requestWithdrawal, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.requestOtpWithdrawSuccess(data));
    }
  } catch (e) {
    yield notification.error(e.response.data.message);
  } finally {
    yield put(actions.setIsLoadingRequestOtpWithdraw(false));
  }
}

function* watchVerifyWithdrawal(value) {
  yield put(actions.setIsLoadingVerifyOtpWithdraw(true));
  const state = yield select();
  const { payload } = value;
  try {
    const response = yield call(
      Api.deposit.confirmOtpRequestWithdrawal,
      payload
    );
    const { data } = response;
    if (data.success) {
      yield put(actions.verifyOtpWithdrawSuccess(data));
      yield put(
        actionsDeposit.getDepositSuccess(state.deposit.saldo - payload.amount)
      );
    }
  } catch (e) {
    yield notification.error(e.response.data.message);
  } finally {
    yield put(actions.setIsLoadingVerifyOtpWithdraw(false));
  }
}

const saga = [
  takeLatest(types.REQUEST_OTP_WITHDRAW, watchRequestWithdrawal),
  takeLatest(types.VERIFY_OTP_WITHDRAW, watchVerifyWithdrawal),
];
export default saga;
