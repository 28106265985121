import { put, call, takeLatest, select, delay } from "redux-saga/effects";
import Api from "../../../../services";
import types from "./constants";
import * as actions from "./reducer";
import { notification } from "../../../../helpers/notification";

function* watchGetDeposit(value) {
  yield put(actions.setIsLoadingGetDeposit(true));
  const state = yield select();
  const { payload } = value;
  try {
    const response = yield call(Api.deposit.getSaldo, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDepositSuccess(data.data.balance));
    }
  } catch (e) {
    yield notification.error(e.response.data.message);
    yield put(actions.getDepositSuccess(state.deposit.saldo));
  } finally {
    yield put(actions.setIsLoadingGetDeposit(false));
  }
}

function* watchGetDepositTransaction(value) {
  yield put(actions.setIsLoadingGetDepositTransaction(true));
  const state = yield select();
  const { payload } = value;
  try {
    const response = yield call(Api.deposit.getSaldoTransaction, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDepositTransactionSuccess(data.data));
      yield put(actions.setMessage({ success: true, message: "" }));
    }
  } catch (e) {
    yield notification.error(e.response.data.message);
    yield put(actions.getDepositTransactionSuccess(state.deposit.history));
  } finally {
    yield put(actions.setIsLoadingGetDepositTransaction(false));
  }
}

function* watchRequestWithdrawal(value) {
  yield put(actions.setIsLoadingRequestWithdrawal(true));
  const state = yield select();
  const { payload } = value;
  try {
    const response = yield call(Api.deposit.requestWithdrawal, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.requestWithdrawalSuccess(true));
      yield put(actions.setMessage({ success: true, message: "" }));
    }
  } catch (e) {
    yield notification.error(e.response.data.message);
    yield put(
      actions.requestWithdrawalSuccess(state.deposit.requestWithdrawalSuccess)
    );
  } finally {
    yield put(actions.setIsLoadingRequestWithdrawal(false));
  }
}

function* watchConfirmOtpRequestWithdrawal(value) {
  yield put(actions.setIsLoadingConfirmWithdrawal(true));
  const state = yield select();
  const { payload } = value;
  try {
    yield delay(2000);
    const response = yield call(
      Api.deposit.confirmOtpRequestWithdrawal,
      payload
    );
    const { data } = response;
    if (data.success) {
      yield put(
        actions.getDepositTransactionSuccess([
          data.history,
          ...state.deposit.history,
        ])
      );
      yield put(
        actions.getDepositSuccess(state.deposit.saldo - data.history.amount)
      );
      yield put(actions.confirmWithdrawalSuccess(true));
      yield put(actions.requestWithdrawalSuccess(false));
      yield put(actions.setMessage({ success: true, message: "" }));
    }
  } catch (e) {
    yield notification.error(e.response.data.message);
    yield put(actions.getDepositTransactionSuccess(state.deposit.history));
  } finally {
    yield put(actions.setIsLoadingConfirmWithdrawal(false));
  }
}

function* watchResendOTPWithdrawal(value) {
  yield put(actions.setIsLoadingResendOTPWithdrawal(true));
  const { payload } = value;
  try {
    yield call(Api.deposit.resendOTP, payload);
  } catch (e) {
  } finally {
    yield put(actions.setIsLoadingResendOTPWithdrawal(false));
  }
}

function* watchGetSaldoEst(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingGetBalanceEst(true));
  try {
    const { data } = yield call(Api.deposit.getSaldoEstimated, payload);
    if (data.success) {
      yield put(actions.getBalanceEstSuccess(data.amount));
    }
  } catch (error) {
    yield put(actions.setIsLoadingGetBalanceEst(false));
  } finally {
    yield put(actions.setIsLoadingGetBalanceEst(false));
  }
}
const saga = [
  takeLatest(types.GET_BALANCE, watchGetDeposit),
  takeLatest(types.GET_BALANCE_TRANSACTION, watchGetDepositTransaction),
  takeLatest(types.REQUEST_WITHDRAWAL, watchRequestWithdrawal),
  takeLatest(types.CONFIRM_OTP_WITHDRAWAL, watchConfirmOtpRequestWithdrawal),
  takeLatest(types.RESEND_OTP_WITHDRAWAL, watchResendOTPWithdrawal),
  takeLatest(types.GET_BALANCE_ESTIMATED, watchGetSaldoEst),
];
export default saga;
