import types from "./types";

const initialState = {
  balance: {
    request: {
      success: false,
      loading: false,
    },
    verify: {
      success: false,
      loading: false,
    },
  },
  coin: {
    request: {
      success: false,
      loading: false,
    },
    verify: {
      success: false,
      loading: false,
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_OTP_WITHDRAW_SUCCESS:
      return {
        ...state,
        balance: {
          ...state.balance,
          request: {
            ...state.balance.request,
            success: action.payload.success,
          },
        },
      };
    case types.IS_LOADING_REQUEST_OTP_WITHDRAW:
      return {
        ...state,
        balance: {
          ...state.balance,
          request: {
            ...state.balance.request,
            loading: action.payload.loading,
          },
        },
      };
    case types.RESET_REQUEST_OTP_WITHDRAW:
      return {
        ...state,
        balance: {
          ...state.balance,
          request: initialState.balance.request,
        },
      };
    case types.VERIFY_OTP_WITHDRAW_SUCCESS:
      return {
        ...state,
        balance: {
          ...state.balance,
          verify: {
            ...state.balance.verify,
            success: action.payload.success,
          },
        },
      };
    case types.IS_LOADING_VERIFY_OTP_WITHDRAW:
      return {
        ...state,
        balance: {
          ...state.balance,
          verify: {
            ...state.balance.verify,
            loading: action.payload.loading,
          },
        },
      };
    case types.RESET_VERIFY_OTP_WITHDRAW:
      return {
        ...state,
        balance: {
          ...state.balance,
          verify: initialState.balance.verify,
        },
      };
    default:
      return state;
  }
};

export const requestOtpWithdraw = (payload) => ({
  type: types.REQUEST_OTP_WITHDRAW,
  payload,
});
export const requestOtpWithdrawSuccess = (payload) => ({
  type: types.REQUEST_OTP_WITHDRAW_SUCCESS,
  payload,
});
export const setIsLoadingRequestOtpWithdraw = (payload) => ({
  type: types.IS_LOADING_REQUEST_OTP_WITHDRAW,
  payload,
});
export const resetRequestOtpWithdraw = (payload) => ({
  type: types.RESET_REQUEST_OTP_WITHDRAW,
  payload,
});

export const verifyOtpWithdraw = (payload) => ({
  type: types.VERIFY_OTP_WITHDRAW,
  payload,
});
export const verifyOtpWithdrawSuccess = (payload) => ({
  type: types.VERIFY_OTP_WITHDRAW_SUCCESS,
  payload,
});
export const setIsLoadingVerifyOtpWithdraw = (payload) => ({
  type: types.IS_LOADING_VERIFY_OTP_WITHDRAW,
  payload,
});
export const resetVerifyOtpWithdraw = (payload) => ({
  type: types.RESET_VERIFY_OTP_WITHDRAW,
  payload,
});
