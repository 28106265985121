export default {
  REQUEST_OTP_WITHDRAW: "request-otp-withdraw",
  REQUEST_OTP_WITHDRAW_SUCCESS: "request-otp-withdraw-success",
  IS_LOADING_REQUEST_OTP_WITHDRAW: "is-loading-request-otp-withdraw",
  RESET_REQUEST_OTP_WITHDRAW: "reset-request-otp-withdraw",

  VERIFY_OTP_WITHDRAW: "verify-otp-withdraw",
  VERIFY_OTP_WITHDRAW_SUCCESS: "verify-otp-withdraw-success",
  IS_LOADING_VERIFY_OTP_WITHDRAW: "is-loading-verify-otp-withdraw",
  RESET_VERIFY_OTP_WITHDRAW: "reset-verify-otp-withdraw",
};
