import { put, call, takeLatest } from "redux-saga/effects";

import Api from "../../services";
import types from "./constants";
import * as actions from "./reducer";

function* watchCheckEmailRegister(values) {
  const { payload } = values;
  yield put(actions.setLoadingCheckRegister(true));
  try {
    const response = yield call(Api.register.check.email, payload);
    const { data } = response;
    let result = {
      ...data,
      google: payload.googleSignIn,
    };
    yield put(actions.checkRegisterSuccess(result));
  } catch (error) {
    yield put(actions.setLoadingCheckRegister(false));
  } finally {
    yield put(actions.setLoadingCheckRegister(false));
  }
}
function* watchCheckPhoneNumberRegister(values) {
  const { payload } = values;
  yield put(actions.setLoadingCheckRegister(true));
  try {
    const response = yield call(Api.register.check.phone, payload);
    const { data } = response;
    yield put(actions.checkRegisterSuccess(data));
  } catch (error) {
    yield put(actions.setLoadingCheckRegister(false));
  } finally {
    yield put(actions.setLoadingCheckRegister(false));
  }
}

function* watchRequestEmailRegister(values) {
  const { payload } = values;
  yield put(actions.setLoadingRequestOTPRegister(true));
  try {
    const response = yield call(Api.register.request.email, payload);
    const { data } = response;
    yield put(actions.requestOTPRegisterSuccess(data));
  } catch (error) {
    yield put(actions.setLoadingRequestOTPRegister(false));
  } finally {
    yield put(actions.setLoadingRequestOTPRegister(false));
  }
}
function* watchRequestPhoneNumberRegister(values) {
  const { payload } = values;
  yield put(actions.setLoadingRequestOTPRegister(true));
  try {
    const response = yield call(Api.register.request.phone, payload);
    const { data } = response;
    yield put(actions.requestOTPRegisterSuccess(data));
  } catch (error) {
    yield put(actions.setLoadingRequestOTPRegister(false));
  } finally {
    yield put(actions.setLoadingRequestOTPRegister(false));
  }
}

function* watchVerifyOTPRegister(values) {
  const { payload } = values;
  yield put(actions.setLoadingVerifyOTPRegister(true));
  try {
    const response = yield call(Api.register.verify, payload);
    const { data } = response;
    yield put(actions.verifyOTPRegisterSuccess(data));
  } catch (error) {
    const { data } = error.response;
    if (data) {
      yield put(actions.verifyOTPRegisterSuccess(data));
    }
    yield put(actions.setLoadingVerifyOTPRegister(false));
  } finally {
    yield put(actions.setLoadingVerifyOTPRegister(false));
  }
}
function* watchRegisterUser(values) {
  const { payload } = values;
  yield put(actions.isLoadingRegister(true));
  yield put(actions.checkRegisterSuccess({}));
  try {
    const response = yield call(Api.register.create, payload);
    const { data } = response;
    yield put(actions.registerSuccess(data));
  } catch (error) {
    yield put(actions.setMessageRegister(error.message));
  } finally {
    yield put(actions.clearMessageRegister());
    yield put(actions.isLoadingRegister(false));
  }
}

const saga = [
  takeLatest(types.REGISTER, watchRegisterUser),
  takeLatest(types.CHECK_EMAIL_REGISTER, watchCheckEmailRegister),
  takeLatest(types.CHECK_PHONE_NUMBER_REGISTER, watchCheckPhoneNumberRegister),
  takeLatest(types.REQUEST_OTP_EMAIL_REGISTER, watchRequestEmailRegister),
  takeLatest(
    types.REQUEST_OTP_PHONE_NUMBER_REGISTER,
    watchRequestPhoneNumberRegister
  ),
  takeLatest(types.VERIFY_OTP_REGISTER, watchVerifyOTPRegister),
];

export default saga;
